import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { useRef, StrictMode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { CalendarProvider } from "../../contexts/Calendar";
import { MasterCalendarProvider } from "../../contexts/MasterCalendar";
import MasterCalendar from "../MasterCalendar";
import { SessionChangesQueueProvider } from "../../contexts/SessionChangesQueue";
import { ChangesListProvider } from "../../contexts/ChangesList";
import SessionChangesQueue from "../SessionChangesQueue";
import { CALENDAR_MONTLY_VIEW, DEFAULT_REACT_QUERY_STALE_TIME } from "../../constants";
import TutorTimeOffRequests from "../TutorTimeOffRequests";
import { RailsPathsProvider } from "../../contexts/RailsPathsContext";
import AdminTutorTimeOff from "../AdminTutorTimeOff";
import SubSessionRequests from "../SubSessionRequests";
import SchoolReview from "../SchoolReview";
import SchoolReviewEdit from "../SchoolReview/SchoolReviewEdit";
import SchoolReviewLayout from "../SchoolReview/SchoolReviewLayout";

const AdminApp = (props) => {
  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          keepPreviousData: true,
          staleTime: DEFAULT_REACT_QUERY_STALE_TIME,
        },
      },
    })
  );

  return (
    <QueryClientProvider client={queryClient.current}>
      <ReactQueryDevtools />
      <BrowserRouter basename="/admin">
        <Routes>
          <Route
            path="/master_calendar"
            element={
              <CalendarProvider defaultView={CALENDAR_MONTLY_VIEW}>
                <MasterCalendarProvider>
                  <MasterCalendar />
                </MasterCalendarProvider>
              </CalendarProvider>
            }
          />
          <Route
            path="/session_changes"
            element={
              <SessionChangesQueueProvider>
                <ChangesListProvider>
                  <SessionChangesQueue />
                </ChangesListProvider>
              </SessionChangesQueueProvider>
            }
          />
          <Route
            path="/tutor_time_offs"
            element={
              <StrictMode>
                <RailsPathsProvider paths={props.paths}>
                  <TutorTimeOffRequests />
                </RailsPathsProvider>
              </StrictMode>
            }
          />
          <Route
            path={`/tutors/:id/tutor_time_off`}
            element={
              <StrictMode>
                <AdminTutorTimeOff tutor={props?.tutor} tutorTimeOffs={props?.tutorTimeOffs} />
              </StrictMode>
            }
          />
          <Route
            path="/sub_session_requests/unclaimed"
            element={
              <StrictMode>
                <SubSessionRequests />
              </StrictMode>
            }
          />
          <Route path="/school_review" element={<SchoolReviewLayout />}>
            <Route index element={<SchoolReview schools={props?.schools} />} />
            <Route
              path=":id/edit"
              element={
                <StrictMode>
                  <SchoolReviewEdit
                    fundingSources={props?.fundingSources}
                    programTerms={props?.programTerms}
                    school={props?.school}
                    schoolSeats={props?.schoolSeats}
                    selectedStudents={props?.selectedStudents}
                  />
                </StrictMode>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer bodyClassName="text-sm" position="bottom-right" />
    </QueryClientProvider>
  );
};

export default AdminApp;
