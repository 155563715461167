import { useMutation } from "@tanstack/react-query";

import { post } from "../../api";

export const useSchoolReviewMutation = ({ studentProgramEnrollments }) => {
  return useMutation({
    mutationFn: async () => {
      await post("/admin/school_review", {
        student_program_enrollments: studentProgramEnrollments,
      });
    },
  });
};
