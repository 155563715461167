import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const SchoolReviewSelect = ({ name, onSelect, options, studentId }) => {
  return (
    <Select
      aria-label={`Select ${name} for Student ${studentId}`}
      classNames={{
        control: (state) =>
          state.isFocused
            ? "mt-1 block !min-w-[12rem] !border-brand-500 !border-2 !shadow-sm sm:text-sm  rounded-md"
            : "mt-1 min-w-[12rem]",
      }}
      id={"select-" + name + "-" + studentId}
      isSearchable={false}
      name={name}
      onChange={(option) => onSelect(studentId, name, option.value)}
      options={options.map((option) => ({ value: option.id, label: option.name }))}
    />
  );
};

SchoolReviewSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  studentId: PropTypes.number.isRequired,
};

export default SchoolReviewSelect;
