import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import usePagination from "./usePagination";
import SchoolReviewSelect from "./SchoolReviewSelect";
import { columns, gradeMapping } from "./constants";
import { useSchoolReviewMutation } from "./query";
import { Button, Table } from "@Ignite-Reading/ui-kit/components";
import { toast } from "react-toastify";

const SchoolReviewEdit = ({ fundingSources, programTerms, schoolSeats, selectedStudents }) => {
  const [studentProgramEnrollments, setStudentProgramEnrollments] = useState({});
  const queryMutation = useSchoolReviewMutation({ studentProgramEnrollments });

  const studentProgramEnrollmentValue = useCallback(
    (studentId, fieldName) => {
      return studentProgramEnrollments?.[studentId]?.[fieldName];
    },
    [studentProgramEnrollments]
  );

  const setStudentProgramEnrollmentValue = useCallback((studentId, fieldName, value) => {
    setStudentProgramEnrollments((prevStudentProgramEnrollments) => ({
      ...prevStudentProgramEnrollments,
      [studentId]: {
        ...prevStudentProgramEnrollments[studentId],
        [fieldName]: value,
      },
    }));
  }, []);

  const studentStartDate = useCallback(
    (studentId) => {
      const startDate = studentProgramEnrollmentValue(studentId, "startDate");
      if (startDate) {
        return startDate;
      }

      const programTermId = studentProgramEnrollmentValue(studentId, "programTerm");
      if (programTermId) {
        const programTermStartDate = programTerms.find(
          (programTerm) => programTerm.id === programTermId
        ).start_date;
        setStudentProgramEnrollmentValue(studentId, "startDate", programTermStartDate);
        return programTermStartDate;
      }

      return "";
    },
    [programTerms, setStudentProgramEnrollmentValue, studentProgramEnrollmentValue]
  );

  const submit = useCallback(() => {
    const toastId = toast.loading("Submitting students for program enrollment...");
    queryMutation.mutate(null, {
      onSuccess: () => {
        toast.update(toastId, {
          render: "Student program enrollments submitted.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      },
      onError: () => {
        toast.update(toastId, {
          render:
            "There was a problem submitting students for program enrollment. Please try again.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      },
    });
  }, [queryMutation]);

  const data = selectedStudents.map((student) => ({
    name: student.last_name + "  " + student.first_name,
    id: student.roster_integration_external_id,
    grade: gradeMapping[student.grade_level],
    email: student.email,
    dateOfBirth: student.date_of_birth,
    hispanicOrLatinoDescent: student.hispanic_or_latino_descent,
    race: student.race,
    englishLanguageLearner: student.english_language_learner,
    languageSpokenAtHome: student.language_spoken_at_home,
    teacherName: student.teacher_last_name + "  " + student.teacher_first_name,
    teacherEmail: student.teacher_email,
    lunchProgram: student.lunch_program,
    specialEducationProgram: student.special_education_program,
    specialEducationProgramAccommodations: student.special_education_program_accommodations,
    tutoringTimeSlot: schoolSeats[student.school_seat_id],
    programTerms: (
      <SchoolReviewSelect
        name="programTerm"
        onSelect={setStudentProgramEnrollmentValue}
        options={programTerms}
        studentId={student.id}
      />
    ),
    fundingSources: (
      <SchoolReviewSelect
        name="fundingSource"
        onSelect={setStudentProgramEnrollmentValue}
        options={fundingSources}
        studentId={student.id}
      />
    ),
    programStartDate: (
      <input
        aria-label={`Student ${student.id} Start Date`}
        data-testid={student.id + "-startDate"}
        id={student.id + "-startDate"}
        type="date"
        autoFocus
        className="-ml-[1px] py-1.5 px-3 leading-none focus:ring-brand-500 focus:border-brand-500 shadow-sm text-sm border-zinc-300 rounded-md"
        value={studentStartDate(student.id)}
        onChange={(e) => setStudentProgramEnrollmentValue(student.id, "startDate", e.target.value)}
      />
    ),
  }));

  const rowsPerPage = 20;

  const { currentData, onChangePage, pages, currentPage } = usePagination({
    rowsPerPage,
    data,
  });

  return (
    <div className="py-6">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Review Student Information</h1>
          <p className="mt-2 text-sm text-gray-700">Review and Edit Student Information.</p>
        </div>
      </div>
      <div className="mt-6 pt-6 border-t border-gray-200 flex items-center"></div>
      <Table
        data-testid="school-review-table"
        cellSpacing="default"
        columns={columns}
        data={currentData}
        pagination={{
          "aria-label": "School Review Pagination",
          count: pages,
          onChange: onChangePage,
          page: currentPage,
        }}
        defaultSortKey="name"
        noDataMessage="There are no students that need to be reviewed."
      />
      <div className="pt-6 space-x-5 text-sm text-zinc-500 flex items-center justify-end">
        <p>Clicking Submit will create the program enrollment and initiate the pairing process.</p>
        <Button onClick={submit} className="button-primary text-base" data-testid="submit-button">
          <p className="px-5">Submit</p>
        </Button>
      </div>
    </div>
  );
};

SchoolReviewEdit.propTypes = {
  fundingSources: PropTypes.array.isRequired,
  programTerms: PropTypes.array.isRequired,
  schoolSeats: PropTypes.object.isRequired,
  selectedStudents: PropTypes.array.isRequired,
};
export default SchoolReviewEdit;
