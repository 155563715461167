import { useCallback, useMemo, useState } from "react";

const usePagination = ({ rowsPerPage, data }) => {
  const pages = Math.ceil(data.length / rowsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = data.slice(indexOfFirstRow, indexOfLastRow);
  const onChangePage = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);
  return useMemo(
    () => ({
      currentData,
      onChangePage,
      pages,
      currentPage,
    }),
    [currentData, currentPage, onChangePage, pages]
  );
};

export default usePagination;
