export const columns = [
  {
    id: "name",
    label: "Last Name First Name",
  },
  {
    id: "grade",
    label: "Grade",
  },
  {
    id: "tutoringTimeSlot",
    label: "Tutoring Time Slot",
  },
  {
    id: "programTerms",
    label: "Program Terms",
  },
  {
    id: "programStartDate",
    label: "Program Start Date",
  },
  {
    id: "fundingSources",
    label: "Funding Source",
  },
  {
    id: "email",
    label: "Student Email",
  },
  {
    id: "dateOfBirth",
    label: "Student DOB",
  },
  {
    id: "hispanicOrLatinoDescent",
    label: "Hispanic or Latino Descent",
  },
  {
    id: "race",
    label: "Race",
  },
  {
    id: "englishLanguageLearner",
    label: "Multilingual Learner",
  },
  {
    id: "languageSpokenAtHome",
    label: "Language Spoken at Home",
  },
  {
    id: "teacherName",
    label: "Teacher Name",
  },
  {
    id: "teacherEmail",
    label: "Teacher Email",
  },
  {
    id: "id",
    label: "Student ID Number",
  },
  {
    id: "lunchProgram",
    label: "Lunch Program",
  },
  {
    id: "specialEducationProgram",
    label: "Special Education Program",
  },
  {
    id: "specialEducationProgramAccommodations",
    label: "Special Education Program Accommodations",
  },
];

export const gradeMapping = {
  pre_kindergarten: "Pre-K",
  kindergarten: "K",
  first_grade: "1st",
  second_grade: "2nd",
  third_grade: "3rd",
  fourth_grade: "4th",
  fifth_grade: "5th",
  sixth_grade: "6th",
  seventh_grade: "7th",
  eighth_grade: "8th",
  ninth_grade: "9th",
  tenth_grade: "10th",
  eleventh_grade: "11th",
  twelfth_grade: "12th",
};
